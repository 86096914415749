
import { Component, Vue } from 'vue-property-decorator';
import { mapState, mapGetters } from 'vuex';
import { HttpErrorHandler, IUserLoginInfo } from '@/lib/helpers';
import axios, { AxiosRequestConfig } from 'axios';
import { apiUrls } from '@/store';
import { DISABLE_DEMO_MODE } from './store/mutation-types';

@Component({
  computed: mapState(['loading']),
})
export default class App extends Vue {
  protected needsLogin: boolean = false;
  protected userLoginInfo: IUserLoginInfo = { username: undefined, password: undefined };

  public created() {
    this.$store.state.userClient.validate()
      .then( (valid: boolean) => {
        this.needsLogin = false;
        if (valid === true) {
          this.$store.commit(DISABLE_DEMO_MODE);
        }
        this.$store.dispatch('fetchMosaics')
        ;
      })
      .catch( (err: any) => {
        if ( err.response && err.response.status && (err.response.status === 401 ) ) {
          this.needsLogin = true;
          sessionStorage.removeItem('mvauth');
        }
      });
  }

  protected get isEmpty() {
    return this.$store.getters.isEmpty;
  }

  protected submitForm( formName: string ) {
    (this.$refs[formName] as any)
      .validate( (valid: boolean) => {
          if (valid) {
            const unpw = `${this.userLoginInfo.username}:${this.userLoginInfo.password}`;
            axios.post(`${apiUrls.mapvault}/api/User`, {}, {
              headers: {
                Authorization: `Basic ${window.btoa(unpw)}`,
              }}).then( (resp: any) => {
                if (resp && resp.data === true ) {
                  this.$store.commit(DISABLE_DEMO_MODE);
                }
                sessionStorage.setItem('mvauth', `Basic ${window.btoa(unpw)}`);
                const instance = this.$store.state.client.getInstance();
                instance.interceptors.request.use( (req: AxiosRequestConfig) => {
                  const mvauth = sessionStorage.getItem('mvauth');
                  if (mvauth) {
                    req.headers.authorization = mvauth;
                  }
                  return req;
                });
                this.$store.dispatch('fetchMosaics');
              }).catch( HttpErrorHandler )
              ;
          }
        });
  }
}
