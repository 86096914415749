
import { Component, Prop, Vue } from 'vue-property-decorator';
import NavHeader from '@/components/NavHeader.vue';
import { mapGetters, mapState } from 'vuex';

import DetailsModal from '@/components/DetailsModal.vue';
import DynamicModal from '@/components/DynamicModal.vue';
import { SET_CURRENT } from '@/store/mutation-types';
import { ProductDetails } from '@/lib/mv-web';

@Component({
  components: {
    DetailsModal,
    DynamicModal,
    NavHeader,
  }, computed: {
    ...mapState(['currentTopic']),
    ...mapGetters(['currentMosaicDetails']),
  },
})
export default class BaseLayout extends Vue {
  protected get showTopicModal(): boolean {
    return this.$store.state.currentTopic !== null;
  }

  protected set showTopicModal( b: boolean ) {
    if (b !== true) {
      this.$store.commit('setTopic', null );
    }
  }

  protected get currentMosaicGuid() {
    const gid = this.$store.state.currentMosaicGuid;
    if ( gid != null && !this.$store.state.details.some( (d: ProductDetails) => d.guid === gid) ) {
      this.$store.dispatch('fetchDetails', gid)
        .then( (current: ProductDetails) => {
          this.$store.commit(SET_CURRENT, gid);
        });
      this.$store.commit(SET_CURRENT, null);
      return null;
    }
    return gid;
  }

  protected set currentMosaicGuid( gid: string|null ) {
    this.$store.commit(SET_CURRENT, gid);
  }
}
