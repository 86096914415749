
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { mapState } from 'vuex';
import { Topics } from '@/components/aboutTopics';

import Welcome from './aboutTopics/Welcome.vue';

@Component({
  components: Object.fromEntries(Object.entries(Topics).map( ([k, v], i: number) => [k, Topics[k].component])),
})
export default class DynamicModal extends Vue {
  @Prop({default: 'welcome'})
  public topicName!: string;

  @Prop({default: false})
  public showModal!: boolean;

  protected get visible() { return this.showModal; }
  protected set visible( newVisible: boolean) {
    this.$emit('update:showModal', newVisible);
  }

  protected get title(): string {
    const topic = Topics[this.topicName];
    if (!!topic) {
      return topic.title;
    }
    return 'East View Geospatial\'s MapVault';
  }

  protected get currentModalComponent(): any|undefined {
    const topic = Topics[this.topicName];
    if (!!topic && this.$options.components) {
      return this.$options.components[this.topicName];
    }
  }
}
