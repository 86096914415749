// import { FeatureLike } from 'ol/Feature';

import { ProductDetails } from '@/lib/mv-web';
import { SET_CURRENT_MOSAIC, SET_SHOW_LEGEND, SET_SHOW_METADATA } from './mutation-types';

export const MosaicViewModule = {
  state: () => ({
    current: null as ProductDetails | null,
    showMetadata: false,
    showLegend: false,
  }),
  mutations: {
    [SET_CURRENT_MOSAIC](state: any, details: ProductDetails) {
      state.current = details;
    },
    [SET_SHOW_METADATA](state: any, flag: boolean) {
      state.showMetadata = flag;
    },
    [SET_SHOW_LEGEND](state: any, flag: boolean) {
      state.showLegend = flag;
    },
  },
  actions: {
    // fetchDetails({ state, commit, rootState }, gid: string) {
    //   const current = rootState.details.find( (d: ProductDetails) => d.guid === gid );
    //   if ( current != null ) {
    //     return Promise.resolve(current);
    //   }

    // }
  },
  getters: {
    hasMetadata: (state: any) => state.current && state.current.hasAttachment && state.current.hasAttachment[2],
    hasLegend: (state: any) => state.current && state.current.hasAttachment && state.current.hasAttachment[5],
    // currentDetails(state: any, getters: any, rootState: any) {
    //   if ( state.current && state.current.guid)
    //   return rootState.details.find( (d: ProductDetails) => d.guid === state.selected);
    // },
  },
};
