
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import NavHeader from '@/components/NavHeader.vue';
import ListViewSelectMenu from '@/components/ListViewSelectMenu.vue';
import { ProductDetails } from '@/lib/mv-web';
import { SET_SHOW_LEGEND, SET_SHOW_METADATA, SET_CURRENT } from '@/store/mutation-types';
import { mapGetters } from 'vuex';

@Component({
  components: {
    // DynamicModal,
    ListViewSelectMenu,
    NavHeader,
  },
  computed: mapGetters(['hasLegend', 'hasMetadata']),
})
export default class MainNavHeader extends Vue {
  @Prop()
  public gid!: string;

  protected get details(): ProductDetails {
    return this.$store.getters.mosaicByGuid(this.gid);
  }

  protected showTopic( topic: string) {
    this.$store.commit('setTopic', topic);
  }

  protected get showLegend(): boolean { return this.$store.state.mosaic.showLegend; }
  protected set showLegend( newShow: boolean ) {
    this.$store.commit(SET_SHOW_LEGEND, newShow);
  }

  protected get showMetadata(): boolean { return this.$store.state.mosaic.showMetadata; }
  protected set showMetadata( newShow: boolean ) {
    this.$store.commit(SET_SHOW_METADATA, newShow);
  }

  protected onTitleClicked() {
    this.$store.commit(SET_CURRENT, this.details.guid);
  }
}
