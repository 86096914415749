import axios, { AxiosRequestConfig } from 'axios';

const authSessionItem = 'mvauth';
axios.interceptors.request.use( (req: AxiosRequestConfig) => {
  if (sessionStorage.getItem(authSessionItem)) {
    req.headers.authorization = sessionStorage.getItem(authSessionItem);
  }
  return req;
});

import Vue from 'vue';
import ElementUI from 'element-ui';
import VueAuthImage from 'vue-auth-image';

import '@/styles/variables.scss';
import '@/styles/element-theme-chalk/src/index.scss';
import '@/styles/site.scss';

import '@/styles/navbar.scss';
import '@/styles/vuelayers/ol.scss';

import App from './App.vue';
import router from './router';
import store from './store';

Vue.use(ElementUI);
Vue.use(VueAuthImage);
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
