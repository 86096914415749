export const UPDATE_MOSAICS = 'updateMosaics';
export const UPDATE_DETAILS = 'updateDetails';
export const SET_LOADING = 'setLoading';
export const SET_QUERY = 'setQuery';
export const SET_TOPIC = 'setTopic';
export const SET_CURRENT = 'setCurrentMosaicGuid';

export const SET_INDEXMAP_SELECTION = 'setIndexMapSelection';

export const SET_SHOW_METADATA = 'setShowMetadata';
export const SET_SHOW_LEGEND = 'setShowLegend';
export const SET_CURRENT_MOSAIC = 'setCurrentMosaic';

export const DISABLE_DEMO_MODE = 'disableDemoMode';
