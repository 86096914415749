
import { ProductDetails } from '@/lib/mv-web';
import { SET_CURRENT_MOSAIC } from '@/store/mutation-types';
import { Component, Prop } from 'vue-property-decorator';
import BaseLayout from './BaseLayout.vue';

@Component({
})
export default class MosaicPage extends BaseLayout {
  @Prop()
  public gid!: string;

  public mounted() {
    this.$store
      .dispatch('fetchDetails', this.gid)
      .then( ( d: ProductDetails) => {
        this.$store.commit( SET_CURRENT_MOSAIC, d);
      });
  }
}
