export const Topics: {[label: string]: any} = {
  'about-welcome': {
    title: 'Welcome to MapVault',
    component: () => import(/* webpackChunkName: "about" */ './Welcome.vue'),
  },
  'about-contact-us': {
    title: 'Contact Us',
    component: () => import(/* webpackChunkName: "about" */ './ContactUs.vue'),
  },
  'about-mapvault': {
    title: 'About MapVault',
    component: () => import(/* webpackChunkName: "about" */ './MapVault.vue'),
  },
  'about-evg': {
    title: 'About East View Geospatial',
    component: () => import(/* webpackChunkName: "about" */ './EVG.vue'),
  },
  'about-FAQs': {
    title: 'Frequently Asked Questions',
    component: () => import(/* webpackChunkName: "about" */ './FAQs.vue'),
  },
};
