import { Vue } from 'vue-property-decorator';
import { Notification } from 'element-ui';

export const FileDownloadErrorHandler = (ctx: Vue, fileName: string) => (err: any) => {
  let reason = '';
  let contact = '<p>Please contact East View support.</p>';
  if ( err && err.response ) {
    const status = err.response.status;
    if (status === 401 || status === 403) {
      reason = ' because you do not have sufficient access privileges';
      contact = '';
    }
    if (status === 404 ) {
      reason = ' because it is missing';
    }
    if (status === 500) {
      reason = ' because of a server error';
      contact = '<p>If the issue persists, please contact East View support.</p>';
    }
  }
  let message = `<p>The requested file could not be downloaded${reason}:
  <span class='verbatim'>${fileName}</span>.</p>`;
  message += contact;
  ctx.$notify.error({
    duration: 12000,
    dangerouslyUseHTMLString: true,
    title: 'Download failed',
    message,
  });
};

export const HttpErrorHandler = (err: any) => {
  let reason = 'unknown';
  let contact = '<p>Please contact East View support.</p>';
  if ( err && err.response ) {
    const status = err.response.status;
    if (status === 401 || status === 403) {
      reason = 'you do not have sufficient access privileges';
      contact = '';
    }
    if (status === 404 ) {
      reason = 'the resource does not exist';
    }
    if (status === 500) {
      reason = 'an internal failure prevented the server from replying.';
      contact = '<p>If the issue persists, please contact East View support.</p>';
    }
  }
  let message = `<p>The request could not be processed.<br/>Reason: ${reason}</p>`;
  message += contact;
  Notification({
    title: 'Request failed',
    duration: 60000,
    dangerouslyUseHTMLString: true,
    message,
    type: 'error',
    // //     position: 'bottom-right',
    },
  );
};

export interface IUserLoginInfo {
  username?: string;
  password?: string;
}
