import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
// import Home from '../views/Home.vue';
import MainNavHeader from '@/components/MainNavHeader.vue';
import MosaicNavHeader from '@/components/mosaic/NavHeader.vue';
// import MosaicCardList from '@/components/MosaicCardList.vue';
const  MosaicCardList = () => import(/* webpackChunkName: "Browser" */ '@/components/MosaicCardList.vue');
// import MosaicViewer from '@/components/mosaic/Viewer.vue';
// import IndexMap from '@/components/IndexMap.vue';
// import IndexTable from '@/components/IndexTable.vue';
// import TermsNConditions from '@/components/T&C.vue';

// import BaseLayout from '../views/BaseLayout.vue';
// import BrowserLayout from '../views/BrowserLayout';
import MosaicPage from '../views/MosaicPage';

Vue.use(VueRouter);

const routes: RouteConfig[] = [
  {
    path: '/',
    // name: 'Home',
    redirect: '/table',
    meta: {
      title: 'MapVault',
    },
    component: () => import(/* webpackChunkName: "Browser" */ '../views/BrowserLayout'),
    children: [
      // {
      //   path: '',
      //   components: { navbar: MainNavHeader },
      // },
      {
        path: 'tags',
        name: 'tagList',
        components: {
          navbar: MainNavHeader,
          default: MosaicCardList,
        },
        props: {
          default: { listViewKind: 'Tags' },
        },
      },
      {
        path: 'list',
        name: 'cardList',
        components: {
          navbar: MainNavHeader,
          default: MosaicCardList,
        },
        props: {
          default: { listViewKind: 'List' },
        },
      },
      {
        path: 'map',
        name: 'indexMap',
        components: {
          navbar: MainNavHeader,
          default: () => import(/* webpackChunkName: "IndexMap" */ '@/components/IndexMap.vue'),
        },
      },
      {
        path: 'table',
        name: 'indexTable',
        components: {
          navbar: MainNavHeader,
          default: () => import(/* webpackChunkName: "Browser" */ '@/components/IndexTable.vue'),
        },
      },
      {
        // TODO: move the terms route into its own ( not a child of /)
        // and create a separate component; see BaseLayout comment
        path: 'terms',
        name: 'terms_n_conditions',
        components: {
          navbar: MainNavHeader,
          default: () => import(/* webpackChunkName: "Browser" */ '@/components/T&C.vue'),
        },
      },
    ],
  },
  {
    path: '/pUrl/Mosaic/:gid',
    component: MosaicPage,
    props: true,
    children: [
      {
        name: 'Mosaic',
        path: '',
        components: {
          navbar: MosaicNavHeader,
          default: () => import(/* webpackChunkName: "Viewer" */ '@/components/mosaic/Viewer.vue'),
        },
        props: {
          navbar: true,
          default: true,
        },
      },
    ],
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
  },
  {
    path: '/UserGuide',
    beforeEnter: () => {
      window.location.href = '/MapVault/UserGuide/Welcome.html';
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
