
import { ProductDetails, FileResponse } from '@/lib/mv-web';
import { Component, Prop, Vue } from 'vue-property-decorator';

import { Tooltip } from 'element-ui';

import { FileDownloadErrorHandler } from '@/lib/helpers';

import { mapState } from 'vuex';
// import { type } from 'os';

@Component({
  components: { Tooltip },
  computed: mapState(['mosaics', 'details']),
})
export default class DetailsModal extends Vue {
  @Prop({default: null})
  public id!: string | null;

  @Prop({default: null})
  public currentMosaicDetails!: ProductDetails | null;

  protected get current(): ProductDetails | null {
    if (!this.id) {
      return null;
    }
    return this.$store.state.details.find( (d: ProductDetails) => d.guid === this.id);
  }

  protected get available(): boolean {
    const c = this.current;
    return !!c && (c.authorized === true);
  }

  protected get visible() { return !(!this.id || !this.current); }
  protected set visible( newVisible: boolean) {
    if (newVisible !== true) {
      this.$emit('update:id', null);
    }
  }

  protected get title() {
    if (this.current !== null) {
      return this.current.title;
    }
    return 'No mosaic selected';
  }

  protected hasAttachment( typeId: number): boolean {
    if ( this.current && this.current.hasAttachment && this.current.hasAttachment.length > typeId && typeId > -1) {
      return this.current.hasAttachment[typeId];
    }
    return false;
  }

  protected download( fileName: string, typeId: number | undefined) {
    const p = typeId !== undefined ?
      this.$store.state.client.attachment(this.id, typeId) :
      this.$store.state.client.metadata(this.id, fileName);

    p.then((f: FileResponse) => {
      const fileURL = window.URL.createObjectURL(f.data);
      const fileLink = document.createElement('a');

      fileLink.href = fileURL;
      fileLink.setAttribute('download', f.fileName || fileName);
      const dlEl = document.body.appendChild(fileLink);
      fileLink.click();
      // remove the element?
      // document.body.removeChild(dlEl);
    }).catch( FileDownloadErrorHandler(this, fileName) );
  }

  protected handleViewMosaic() {
    if (this.id === null ) {
      return;
    }
    this.$router.push({name: 'Mosaic', params: {gid: this.id}});
    this.visible = false;
  }

  protected handleRequestAccess() {
    if (this.current === null ) {
      return;
    }
    const path = `mailto:MapVault@eastview.com?subject=${encodeURI('MapVault Access request - ' + this.current.title)}`;
    window.open(path, '_blank');
    this.visible = false;
  }
}
