import _ from 'lodash';
import Vue from 'vue';
import Vuex from 'vuex';
// import { indexMapModule } from './map';

import { MosaicClient, Mosaic, ProductDetails, UserClient } from '@/lib/mv-web';
import {
  DISABLE_DEMO_MODE,
  SET_CURRENT,
  SET_LOADING,
  SET_QUERY,
  SET_TOPIC,
  UPDATE_DETAILS,
  UPDATE_MOSAICS,
} from './mutation-types';
import { MosaicViewModule } from './mosaic';
import { HttpErrorHandler } from '@/lib/helpers';

Vue.use(Vuex);

export const apiUrls = { mapvault: process.env.VUE_APP_MAPVAULT_API_URL };

export default new Vuex.Store({
  state: {
    mosaics: [] as Mosaic[],
    details: [] as ProductDetails[],
    basemap: process.env.VUE_APP_BASEMAP,
    apiUrls,
    loading: false,
    mode: 'demo',
    query: '',
    currentMosaicGuid: null,
    currentTopic: null,
    client: new MosaicClient(apiUrls.mapvault),
    userClient: new UserClient(apiUrls.mapvault),
  },
  getters: {
    isEmpty: (state) => !( state.mosaics.length > 0 ),
    isDemo: (state) => {
      return state.mode === 'demo';
    },
    filteredMosaics: (state) => (
      (!state.query) ?
        state.mosaics :
        state.mosaics.filter(
          (m: Mosaic) => m.title && m.title.toLowerCase().indexOf(state.query) > -1,
        )
      ),
    filteredProductDetails: (state) => (
      (!state.query) ?
        state.details :
        state.details.filter(
          (m: ProductDetails) => m.title && m.title.toLowerCase().indexOf(state.query) > -1,
        )
      ),
    mosaicByGuid: (state) => (guid: string) => {
      return state.mosaics.find( ( m: Mosaic ) => m.guid === guid);
    },
    detailsByGuid: (state) => (guid: string) => {
      return state.details.find( ( d: ProductDetails ) => d.guid === guid);
    },
    currentMosaicDetails: (state) => {
      if (state.currentMosaicGuid === null) {
        return null;
      }
      return state.details.find( ( d: ProductDetails ) => d.guid === state.currentMosaicGuid);
    },
  },
  mutations: {
    [SET_LOADING](state, loading) {
      state.loading = loading;
    }, [DISABLE_DEMO_MODE](state, mode: string = 'main') {
      state.mode = mode;
    }, [SET_QUERY](state, q) {
      state.query = q.toLowerCase();
    }, [SET_TOPIC](state, t) {
      if ( state.currentTopic === 'about-welcome') {
        // once the welcome screen is dismissed, we record that dismissal
        // in local storage so the user does not get prompted again when they come back
        localStorage.setItem('dismissWelcome', 'true');
      }
      state.currentTopic = t;
    }, [SET_CURRENT](state, gid) {
      state.currentMosaicGuid = gid;
    }, [UPDATE_MOSAICS](state, mosaics) {
      state.mosaics = mosaics;
    }, [UPDATE_DETAILS](state, details: ProductDetails[]) {
      state.details = _.sortBy( details, [(v: any) => !v.authorized, 'title'] );
    },
  },
  actions: {
    fetchMosaics({commit}) {
      commit(SET_LOADING, true);
      const client = this.state.client;
      return client.get()
        .then((mosaics) => {
          if (this.state.mode === 'demo') {
            client.getFullList().then( (publicMosaics) => {
              // merge the public and user mosaics
              const guids = mosaics.map( (m: Mosaic) => m.guid);
              commit(UPDATE_MOSAICS, mosaics.concat(publicMosaics.filter( (m: Mosaic) => !guids.includes(m.guid))));
            });
          } else {
            commit(UPDATE_MOSAICS, mosaics);
          }
        })
        // .catch( HttpErrorHandler )
        .finally(() => commit(SET_LOADING, false));
    },
    fetchDetails({state, commit}, gid: string) {
      const current = state.details.find( (d: ProductDetails) => d.guid === gid );
      if ( current != null ) {
        return Promise.resolve(current);
      }
      commit(SET_LOADING, true);
      const client = state.client;
      return client.details(gid)
        .then((result: ProductDetails) => {
          commit( UPDATE_DETAILS, state.details.concat(result) );
          return result;
        })
        .catch( HttpErrorHandler )
        .finally(() => commit(SET_LOADING, false));
    },
  },
  modules: {
    mosaic: MosaicViewModule,
  },
});
