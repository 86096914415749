
import { Component, Vue } from 'vue-property-decorator';
import NavHeader from '@/components/NavHeader.vue';
import ListViewSelectMenu from '@/components/ListViewSelectMenu.vue';
import { SET_QUERY, SET_TOPIC } from '@/store/mutation-types';
import { mapGetters } from 'vuex';

@Component({
  computed: mapGetters(['isDemo']),
  components: {
    // DynamicModal,
    ListViewSelectMenu,
    NavHeader,
  },
})
export default class MainNavHeader extends Vue {
  public get query(): string {
    return this.$store.state.query;
  }

  public set query( q: string) {
    this.$store.commit(SET_QUERY, q);
  }

  protected showTopic( topic: string) {
    this.$store.commit(SET_TOPIC, topic);
  }
}
